.layout {
    min-height: 100vh;
    justify-content: center;
    align-items: center;
    background-color: #f5f5f5;
}

.card {
    width: 400px;
    box-shadow: none !important;
}

.avatar {
    width: 100%;
    height: 100%
}

.upload-button .ant-upload-select {
    width: 200px !important;
    height: 200px !important;
}

.upload-button.ant-upload-wrapper {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
}